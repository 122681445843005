// Just Include this Polyfill for working with IE
import "promise-polyfill/src/polyfill";

Promise.all([
  import("react-app-polyfill/ie11"),
  import("react-app-polyfill/stable"),
  import("babel-polyfill"),
  import("core-js/stable"),
  import("./Bootstrap")
])
  .then((modules) => {
    // Init Last Module Bootstrap
    modules[4].default();
  })
  .catch((e) => {
    console.error("Error has Ocurred at Application Start", e);
  });
